.rectangle {
  cursor: pointer;
  border: 3px solid var(--color-black);
  padding: 0;
  background-color: var(--color-white);
  position: absolute;
  height: calc(100% + 50px);
  width: calc(100% - 50px);
  top: -25px;
  right: 25px;
  bottom: -25px;
  left: 25px;
  box-sizing: border-box;
  transform: rotate(90deg);
}
.rectangle:hover {
  background-color: var(--color-black);
}
.about {
  margin: 0;
  position: absolute;
  top: calc(50% - 19.5px);
  left: calc(50% - 54px);
  font-size: var(--font-size-sm);
  font-weight: 700;
  font-family: var(--font-poppins);
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  width: 109px;
}
.about:hover {
  color: var(--color-white);
}
.homepageButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 394px;
  left: calc(50% - 196px);
  width: 190px;
  height: 140px;
}
.homepageButton:hover,
.project:hover {
  background-color: var(--color-rosybrown);
}
.contact {
  position: absolute;
  width: calc(100% - 42px);
  top: calc(50% - 22px);
  left: 22px;
  display: inline-block;
}
.contactWrapper,
.projectChild {
  position: absolute;
  background-color: var(--color-white);
  border: 3px solid var(--color-black);
  box-sizing: border-box;
}
.contactWrapper {
  text-decoration: none;
  top: calc(50% - 1074px);
  left: calc(50% + 14px);
  width: 190px;
  height: 140px;
  color: inherit;
}
.projectChild {
  height: calc(100% + 50px);
  width: calc(100% - 50px);
  top: -25px;
  right: 25px;
  bottom: -25px;
  left: 25px;
  transform: rotate(90deg);
}
.projects {
  position: absolute;
  width: calc(100% - 65px);
  top: calc(50% - 20px);
  left: 33px;
  font-size: var(--font-size-sm);
  display: inline-block;
  font-family: var(--font-poppins);
  color: var(--color-black);
  text-align: center;
}
.project {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 394px;
  left: calc(50% + 14px);
  width: 190px;
  height: 140px;
}
.contact1 {
  position: absolute;
  width: calc(100% - 67px);
  top: calc(50% - 21px);
  left: 34px;
  display: inline-block;
}
.contactContainer {
  text-decoration: none;
  position: absolute;
  top: calc(50% - 1074px);
  left: calc(50% - 196px);
  background-color: var(--color-white);
  border: 3px solid var(--color-black);
  box-sizing: border-box;
  width: 190px;
  height: 140px;
  color: inherit;
}
.img0064Icon {
  top: calc(50% - 909px);
  left: calc(50% - 621px);
}
.img0064Icon,
.img0064Icon1,
.img0064Icon2 {
  position: absolute;
  width: 400px;
  height: 300px;
  object-fit: cover;
}
.img0064Icon1 {
  top: 394px;
  left: calc(50% - 619px);
}
.img0064Icon2 {
  top: calc(50% - 909px);
  left: calc(50% - 196px);
}
.img0064Icon3 {
  top: 394px;
  left: calc(50% + 234px);
}
.img0064Icon3,
.img0064Icon4,
.sophieknits300x400pxLogoIcon {
  position: absolute;
  width: 400px;
  height: 300px;
  object-fit: cover;
}
.img0064Icon4 {
  top: calc(50% - 909px);
  left: calc(50% + 229px);
}
.sophieknits300x400pxLogoIcon {
  top: 69px;
  left: calc(50% - 196px);
}
.about1 {
  position: absolute;
  width: calc(100% + 1px);
  top: calc(50% - 41px);
  left: 0;
  display: inline-block;
}
.component62 {
  margin: 0;
  position: absolute;
  width: calc(100% - 339px);
  top: 0;
  right: 170px;
  left: 169px;
  height: 82px;
  font-size: inherit;
  font-family: inherit;
}
.aFreelanceDesigner {
  margin: 0;
}
.aFreelanceDesignerContainer {
  position: absolute;
  width: calc(100% + 1px);
  top: 106px;
  left: 0;
  font-size: 35px;
  display: inline-block;
  height: 207px;
}
.img2998Icon {
  position: absolute;
  bottom: 0;
  left: calc(50% - 271.5px);
  width: 600px;
  height: 450px;
  object-fit: cover;
}
.component72,
.homepage {
  text-align: center;
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.component72 {
  position: absolute;
  bottom: 94px;
  left: calc(50% - 656px);
  width: 1255px;
  height: 795px;
  font-size: 60px;
}
.homepage {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 3256px;
  font-size: var(--font-size-sm);
}
