@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.comingSoonChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  border: 3px solid var(--color-darkorange);
  box-sizing: border-box;
}
.comingSoon1 {
  position: absolute;
  width: calc(100% - 19px);
  top: calc(50% - 31px);
  left: 10px;
  display: inline-block;
}
.comingSoon,
.x400pxsolidbackgroundIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.x400pxsolidbackgroundIcon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.sophieknitInstagramOrange {
  top: 0;
  cursor: crosshair;
}
.sophieknitInstagram:hover,
.sophieknitInstagramOrange:hover {
  cursor: none;
}
.sophieknitInstagram:hover .x400pxsolidbackgroundIcon,
.sophieknitInstagramOrange:hover .x400pxsolidbackgroundIcon {
  display: none;
}
.sophieknitInstagram,
.sophieknitInstagram1,
.sophieknitInstagramOrange {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  height: 300px;
}
.sophieknitInstagram {
  top: calc(50% - 150px);
}
.sophieknitInstagram:hover .comingSoon1 {
  color: var(--color-springgreen);
  cursor: crosshair;
}
.sophieknitInstagram:hover .comingSoonChild {
  border: 3px solid var(--color-springgreen);
  box-sizing: border-box;
  cursor: crosshair;
}
.sophieknitInstagram1 {
  bottom: 0;
  cursor: crosshair;
}
.sophieknitInstagram1:hover {
  display: flex;
  cursor: crosshair;
}
.sophieknitInstagram1:hover .x400pxsolidbackgroundIcon {
  display: none;
}
.sophieknitInstagram1:hover .comingSoon1 {
  color: var(--color-darkviolet);
}
.sophieknitInstagram1:hover .comingSoonChild {
  border: 3px solid var(--color-darkviolet);
  box-sizing: border-box;
}
.sophieknitInstagramOrangeParent {
  position: relative;
  width: 400px;
  height: 940px;
  flex-shrink: 0;
}
.rectangleDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  border: 3px solid var(--color-mediumslateblue);
  box-sizing: border-box;
}
.sophieknitInstagramBlue {
  top: 0;
  cursor: crosshair;
  color: var(--color-mediumslateblue);
}
.sophieknitInstagram2:hover,
.sophieknitInstagram3:hover,
.sophieknitInstagramBlue:hover {
  cursor: none;
}
.sophieknitInstagram2:hover .x400pxsolidbackgroundIcon,
.sophieknitInstagramBlue:hover .x400pxsolidbackgroundIcon {
  display: none;
}
.sophieknitInstagram2,
.sophieknitInstagram3,
.sophieknitInstagramBlue {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  height: 300px;
}
.sophieknitInstagram2 {
  top: calc(50% - 150px);
}
.sophieknitInstagram2:hover .comingSoon1 {
  color: var(--color-gold);
}
.sophieknitInstagram2:hover .comingSoonChild {
  border: 3px solid var(--color-gold);
  box-sizing: border-box;
}
.sophieknitInstagram3 {
  bottom: 0;
  cursor: crosshair;
}
.sophieknitInstagram3:hover .x400pxsolidbackgroundIcon {
  display: none;
}
.sophieknitInstagram3:hover .comingSoon1 {
  color: var(--color-lightcoral);
}
.comingSoonChild3,
.sophieknitInstagram3:hover .comingSoonChild {
  border: 3px solid var(--color-lightcoral);
  box-sizing: border-box;
}
.comingSoonChild3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  border: 3px solid var(--color-mediumvioletred-100);
}
.sophieknitInstagramPink {
  top: 0;
  cursor: crosshair;
  color: var(--color-mediumvioletred-100);
}
.sophieknitInstagramPink:hover {
  display: flex;
  cursor: none;
}
.sophieknitInstagram4:hover .x400pxsolidbackgroundIcon,
.sophieknitInstagramPink:hover .x400pxsolidbackgroundIcon {
  display: none;
}
.sophieknitInstagram4,
.sophieknitInstagram5,
.sophieknitInstagramPink {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  height: 300px;
}
.sophieknitInstagram4 {
  top: calc(50% - 150px);
}
.sophieknitInstagram4:hover {
  cursor: none;
}
.sophieknitInstagram4:hover .comingSoon1 {
  color: var(--color-darkturquoise);
}
.sophieknitInstagram4:hover .comingSoonChild {
  border: 3px solid var(--color-darkturquoise);
  box-sizing: border-box;
}
.sophieknitInstagram5 {
  bottom: 0;
}
.sophieknitInstagram5:hover .x400pxsolidbackgroundIcon {
  display: none;
}
.sophieknitInstagram5:hover .comingSoon1 {
  color: var(--color-aquamarine);
}
.sophieknitInstagram5:hover .comingSoonChild {
  border: 3px solid var(--color-aquamarine);
  box-sizing: border-box;
}
.groupParent {
  position: absolute;
  height: calc(100% - 118px);
  top: 54px;
  bottom: 64px;
  left: calc(50% - 627px);
  width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-md);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-darkorange);
  font-family: var(--font-poppins);
}
.instagram {
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 617px);
  display: inline-block;
  width: 1201px;
  opacity: 0;
}
.instagram.animate {
  animation: 5s ease 3s 1 normal forwards slide-in-left;
}
.instagramLogoWhiteIcon {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: calc(50% + 173px);
  max-height: 100%;
  width: 132px;
  object-fit: cover;
}
.instagramBanner {
  text-decoration: none;
  position: absolute;
  width: 100%;
  top: calc(50% - 66px);
  right: 0;
  left: 0;
  background-color: var(--color-mediumvioletred-200);
  height: 132px;
  opacity: 0;
  color: inherit;
}
.instagramBanner.animate {
  animation: 3s ease 3s 1 normal forwards slide-in-left;
}
.landingPage1 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 1058px;
  text-align: center;
  font-size: 70px;
  color: var(--color-white);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 768px) {
  .groupParent {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 428px) {
  .groupParent {
    flex-direction: column;
    align-items: center;
  }
}
