@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.repeatGrid7 {
  position: absolute;
  top: 63px;
  left: 45px;
  width: 300px;
  height: 718px;
  object-fit: cover;
}
.comingSoon,
.comingSoonWrapper {
  margin: 0;
  position: absolute;
  opacity: 0;
  font-size: inherit;
  font-family: inherit;
}
.comingSoon {
  top: calc(50% + 317.25px);
  left: calc(50% - 960px);
  font-weight: 700;
  display: inline-block;
  width: 1201px;
}
.comingSoon.animate {
  animation: 5s ease 3s 1 normal forwards slide-in-left;
}
.comingSoonWrapper {
  top: 335.5px;
  left: -765px;
  background-color: var(--color-mediumvioletred-200);
  width: 1920px;
  height: 125.5px;
  transform: rotate(90deg);
}
.comingSoonWrapper.animate {
  animation: 3s ease 3s 1 normal forwards slide-in-left;
}
.iphone1413122 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 844px;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 428px) {
  .comingSoon {
    width: 400px;
    opacity: 0;
    max-width: 400px;
  }
  .comingSoon.animate {
    animation: 1s ease 0s 1 normal forwards fade-in;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
