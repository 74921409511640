@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.sophieknits300x400pxLogoIcon,
.sophieknits300x400pxLogoIcon1 {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 300px;
  object-fit: cover;
}
.sophieknits300x400pxLogoIcon {
  top: calc(50% - 150px);
}
.sophieknits300x400pxLogoIcon1 {
  bottom: 0;
}
.sophieknits300x400pxLogoIcon2 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.sophieknits300x400pxLogo,
.sophieknits300x400pxLogoIcon2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.comingSoonChild,
.sophieknits {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
}
.comingSoonChild {
  width: 100%;
  left: 0;
  background-color: #ff00d8;
  border: 1px solid #707070;
  box-sizing: border-box;
}
.comingSoon1 {
  position: absolute;
  top: calc(50% - 33px);
  left: 15px;
}
.comingSoon,
.first {
  position: absolute;
  top: 0;
  left: 0;
}
.comingSoon {
  height: 100%;
  bottom: 0;
  width: 400px;
  opacity: 0;
}
.first {
  width: 100%;
  right: 0;
  height: 300px;
}
.first:hover .sophieknits {
  display: none;
}
.first:hover .comingSoon {
  display: flex;
}
.first:hover .comingSoon1 {
  color: #ef53d8;
}
.first:hover .comingSoonChild {
  background-color: #0043ff;
}
.first:hover .sophieknits300x400pxLogoIcon2 {
  display: none;
}
.groupChild,
.sophieknits300x400pxLogoIcon3,
.sophieknits300x400pxLogoParent {
  position: relative;
  width: 400px;
  height: 940px;
  flex-shrink: 0;
}
.groupChild,
.sophieknits300x400pxLogoIcon3 {
  object-fit: cover;
}
.sophieknits300x400pxLogoIcon3 {
  height: 300px;
}
.groupParent,
.sophieknits300x400pxLogoGroup {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-md);
}
.sophieknits300x400pxLogoGroup {
  width: 400px;
  height: 940px;
  flex-shrink: 0;
  flex-direction: column;
}
.groupParent {
  position: absolute;
  height: calc(100% - 142px);
  top: 71px;
  bottom: 71px;
  left: calc(50% - 620px);
  width: 1240px;
  flex-direction: row;
}
.comingSoon2,
.comingSoonWrapper {
  margin: 0;
  position: absolute;
  opacity: 0;
  font-family: inherit;
}
.comingSoon2 {
  top: calc(50% - 60.25px);
  left: calc(50% - 580px);
  font-size: inherit;
  font-weight: 700;
  display: inline-block;
  width: 1201px;
}
.comingSoon2.animate {
  animation: 5s ease 3s 1 normal forwards slide-in-left;
}
.comingSoonWrapper {
  width: 100%;
  top: calc(50% - 62px);
  right: 0;
  left: 0;
  background-color: var(--color-mediumvioletred-200);
  height: 125.5px;
  font-size: var(--font-size-lg);
}
.comingSoonWrapper.animate {
  animation: 3s ease 3s 1 normal forwards slide-in-left;
}
.landingPage {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 1082px;
  text-align: center;
  font-size: 48px;
  color: var(--color-white);
  font-family: var(--font-poppins);
}
