@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-sm: 30px;
  --font-size-base: 45px;
  --font-size-lg: 90px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-rosybrown: #c99d8a;
  --color-mediumvioletred-100: #ff3cca;
  --color-mediumvioletred-200: #e326bf;
  --color-aquamarine: #4de5a9;
  --color-darkorange: #ff963c;
  --color-darkturquoise: #11e8f2;
  --color-lightcoral: #ef8a8a;
  --color-gold: #efda4c;
  --color-mediumslateblue: #3c5aff;
  --color-darkviolet: #c511fc;
  --color-springgreen: #66f680;

  /* Gaps */
  --gap-md: 20px;
}
